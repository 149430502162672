import React, { ReactElement } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import Typography from '../Typography';

import './styles.scss';

export interface ConfirmationProps {
  children?: ReactElement | ReactElement[];
  message: string;
}

function Confirmation(props: ConfirmationProps) {
  const { children, message } = props;

  return (
    <div className="confirmation-container">
      <div className="check-icon">
        <FaCheckCircle />
      </div>
      <Typography spaced={2} color="primary" variant="h1">
        {message}
      </Typography>
      {children}
    </div>
  );
}

export default Confirmation;
