import React, { useState } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import {
  FaCcAmex,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
  FaCreditCard,
  FaTrash,
} from 'react-icons/fa';
import Spinner from 'react-bootstrap/Spinner';
import { CheckInput } from '../CanopyForm';
import Typography from '../Typography';
import './styles.scss';
import { IconType } from 'react-icons';

interface CreditCardProps {
  card: PaymentMethod.Card;
  id: string;
  isSelected: boolean;
  onDelete?: () => void;
  onSelect: () => void;
}

const cardTypes = {
  visa: {
    icon: FaCcVisa,
    label: 'Visa',
  },
  mastercard: {
    icon: FaCcMastercard,
    label: 'MasterCard',
  },
  discover: {
    icon: FaCcDiscover,
    label: 'Discover',
  },
  amex: {
    icon: FaCcAmex,
    label: 'American Express',
  },
  diners: {
    icon: FaCcDinersClub,
    label: 'Diners Club',
  },
  jcb: {
    icon: FaCcJcb,
    label: 'JCB',
  },
  unionpay: {
    icon: FaCreditCard,
    label: 'UnionPay',
  },
};

function CreditCard(props: CreditCardProps) {
  const { card, id, isSelected, onDelete, onSelect } = props;
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleSelect = () => {
    onSelect();
  };

  const handleDelete = () => {
    setIsDeleting(true);
    onDelete && onDelete();
  };

  const Icon: IconType = cardTypes[card.brand].icon;

  return (
    <div className="credit-card">
      <div className="card-row">
        <CheckInput
          id={id}
          checked={isSelected}
          name={card.last4}
          onChange={handleSelect}
          type="radio"
        />
        <div className="primary-card-information">
          <div className="credit-card-left-side">
            <Icon className="icon" />
            <Typography>{cardTypes[card.brand].label}</Typography>
            <Typography>{`...${card.last4}`}</Typography>
          </div>
          <Typography>{`Exp: ${card.exp_month}/${card.exp_year}`}</Typography>
        </div>
      </div>
      {onDelete && (
        <div className="credit-card-delete">
          {isDeleting ? (
            <Spinner animation="border" size="sm" className="icon" />
          ) : (
            <FaTrash
              onClick={handleDelete}
              className="icon"
              data-testid={`delete-credit-card-${id}`}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default CreditCard;
