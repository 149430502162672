import React, { ReactElement } from 'react';
import { ToastContainer as Toastify, toast as toaster } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

export function ToastContainer() {
  return (
    <Toastify
      position="top-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}

/* TODO: Add style prop with branded background colors. */
export const toast = {
  error: (content: string | ReactElement) => toaster.error(content),
  info: (content: string | ReactElement) => toaster.info(content),
  success: (content: string | ReactElement) => toaster.success(content),
  warning: (content: string | ReactElement) => toaster.warning(content),
};
