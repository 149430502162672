import React, { HTMLAttributes, ReactElement } from 'react';
import Typography from '../Typography';
import './styles.scss';

export interface FormSectionProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactElement | ReactElement[];
  prominent?: boolean;
  subtitle?: string;
  title: string;
}

function FormSection(props: FormSectionProps): JSX.Element {
  const { children, className, prominent, subtitle, title } = props;

  return (
    <div className={`form-section${className ? ` ${className}` : ''}`}>
      <div className={`form-section-header${prominent ? ' prominent-header' : ''}`}>
        <Typography
          variant={prominent ? 'h2' : 'h4'}
          color={prominent ? 'secondary' : 'primary'}
          uppercase
          spaced={1}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography color={prominent ? 'secondary' : 'gray'} spaced={1}>
            {subtitle}
          </Typography>
        )}
      </div>
      {children}
    </div>
  );
}

export default FormSection;
