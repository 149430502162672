import { useEffect, useState } from 'react';

export default function useResponsiveFontSize(smallFont: number, largeFont: number) {
  const getFontSize = () =>
    window.innerWidth < 450 ? `${smallFont}px` : `${largeFont}px`;
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return fontSize;
}
