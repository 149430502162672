import React from 'react';
import { FaCreditCard } from 'react-icons/fa';
import Button from '../Button';

interface PaymentMethodButtonProps {
  label: string;
  onClick: () => void;
}

function PaymentMethodButton(props: PaymentMethodButtonProps) {
  const { label, onClick } = props;

  return (
    <div className="payment-method-button">
      <FaCreditCard size="2rem" />
      <Button secondary label={label} onClick={onClick} />
    </div>
  );
}

export default PaymentMethodButton;
