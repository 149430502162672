import React from 'react';
import Typography from '../Typography';
import { ProductType } from '../../types';

import './styles.scss';

export interface OrderBannerProps {
  product: ProductType;
  upsell?: {
    onClick: () => void;
    product: ProductType;
  };
}

function OrderBanner(props: OrderBannerProps) {
  const { product, upsell } = props;

  return (
    <div className="order-banner-container">
      <div className="chosen-product">
        <Typography uppercase>Congratulations on Choosing:</Typography>
        <Typography uppercase spaced={2} variant="h4">
          {product.variant}
        </Typography>
        <Typography uppercase>{product.name}</Typography>
      </div>
      <Typography variant="h1">
        {product.price ? `$${product.price / 100}` : 'FREE'}
      </Typography>
      {upsell && (
        <div className="upsell">
          <Typography variant="h6">
            <React.Fragment>
              {`Select `}
              <span onClick={upsell.onClick}>{upsell.product.variant}</span>
              {` if you would like to take advantage of the following:`}
            </React.Fragment>
          </Typography>
          {upsell.product.benefits?.map((benefit, i) => (
            <Typography key={i} variant="caption">
              {benefit}
            </Typography>
          ))}
        </div>
      )}
    </div>
  );
}

export default OrderBanner;
