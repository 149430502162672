import React, { useState } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import Typography from '../Typography';
import Button from '../Button';
import PaymentMethodButton from './PaymentMethodButton';
import CreditCard from './CreditCard';
import './styles.scss';

interface PaymentMethodsProps {
  addNewPaymentMethod: () => void;
  isLoading: boolean;
  onDelete?: (paymentMethod: PaymentMethod) => void;
  onSubmit: (paymentMethod: PaymentMethod) => void;
  paymentMethods: PaymentMethod[];
}

function PaymentMethods(props: PaymentMethodsProps) {
  const { addNewPaymentMethod, isLoading, onDelete, onSubmit, paymentMethods } = props;

  const [
    selectedPaymentMethod,
    setSelectedPaymentMethod,
  ] = useState<PaymentMethod | null>(null);

  const handlePaymentMethodChange = (method: PaymentMethod) => {
    setSelectedPaymentMethod(method);
  };

  const handleSubmit = () => {
    /* istanbul ignore else */
    if (selectedPaymentMethod) {
      onSubmit(selectedPaymentMethod);
    }
  };

  return (
    <div className="payment-methods">
      <Typography variant="h4">Select Payment Method</Typography>
      {paymentMethods.map((method) => (
        <CreditCard
          key={method.id}
          id={method.id}
          // Card can be undefined on the Stripe PaymentMethod object, but we filter them out when setting them in state.
          card={method.card as PaymentMethod.Card}
          isSelected={Boolean(
            selectedPaymentMethod && selectedPaymentMethod.id === method.id,
          )}
          onDelete={onDelete ? () => onDelete(method) : undefined}
          onSelect={() => handlePaymentMethodChange(method)}
        />
      ))}
      <PaymentMethodButton
        label="Pay with a Different Card"
        onClick={addNewPaymentMethod}
      />
      <div className="enroll-with-payment-method">
        <Button
          disabled={!selectedPaymentMethod}
          label="Pay & Enroll"
          loading={isLoading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

export default PaymentMethods;
