import React from 'react';
import Typography from '../Typography';
import Button from '../Button';
import { ProductType } from '../../types';

import './styles.scss';

export interface ProductProps {
  buttonLabel?: string;
  onSelect: () => void;
  product: ProductType;
}

function Product(props: ProductProps) {
  const { buttonLabel, onSelect, product } = props;
  const { banner, benefits, description, disclaimer, name, price, variant } = product;

  return (
    <div className="product-container">
      {banner && (
        <div className="product-banner">
          <Typography spaced={2} uppercase variant="h4">
            {banner}
          </Typography>
        </div>
      )}
      <div className="product-content">
        <div className="product-content-section">
          <Typography spaced={1} uppercase variant="h5">
            {name}
          </Typography>
          <Typography spaced={2} uppercase variant="h4">
            {variant}
          </Typography>
        </div>
        <div className="product-content-section">
          {description && <Typography variant="h5">{description}</Typography>}
          {price !== null ? (
            <Typography variant="h5">
              {price === 0 ? 'FREE' : `$${price / 100}`}
            </Typography>
          ) : null}
        </div>
        <div className="product-benefits">
          {benefits &&
            benefits.map((benefit, i) => <Typography key={i}>{benefit}</Typography>)}
        </div>
        {disclaimer && <Typography variant="caption">{disclaimer}</Typography>}
      </div>
      <Button fullWidth label={buttonLabel || 'Select'} onClick={onSelect} />
    </div>
  );
}

export default Product;
