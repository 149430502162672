import React, { useMemo } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import * as Yup from 'yup';
import BootstrapForm from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, TextInput } from '../CanopyForm';
import PaymentMethodButton from './PaymentMethodButton';
import { useResponsiveFontSize } from '../../hooks';
import './styles.scss';

export type AddPaymentMethodFormValues = {
  name: string;
  zipCode: string;
};

interface AddPaymentMethodForm {
  error: StripeError | null;
  hasPaymentMethods: boolean;
  isLoading: boolean;
  onSubmit: (values: AddPaymentMethodFormValues) => void;
  useExistingPaymentMethod: () => void;
}

const initialNonStripeValues = {
  name: '',
  zipCode: '',
};

const AddPaymentMethodFormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  zipCode: Yup.string()
    .min(5, 'Zip code must be 5 characters in length.')
    .required('Zip code is required.'),
});

function AddPaymentMethodForm(props: AddPaymentMethodForm) {
  const {
    error,
    hasPaymentMethods,
    isLoading,
    onSubmit,
    useExistingPaymentMethod,
  } = props;
  const elementFontSize = useResponsiveFontSize(14, 14);

  const STRIPE_ELEMENTS_OPTIONS = useMemo(
    () => ({
      style: {
        base: {
          fontFamily: 'Lato, Arial, sans-serif',
          fontSize: `${elementFontSize}`,
          fontWeight: '400',
          color: '#495057',

          ':focus': {
            backgroundColor: 'white',
          },

          '::placeholder': {
            color: '#B7BFC7',
          },
        },
      },
      placeholder: '',
    }),
    [elementFontSize],
  );

  const handleSubmit = (values: AddPaymentMethodFormValues) => {
    onSubmit(values);
  };

  return (
    <Form<AddPaymentMethodFormValues>
      buttonDirection="center"
      error={error ? (error.message ? error.message : error.type) : undefined}
      handlePrimaryButtonSubmit={handleSubmit}
      initialValues={initialNonStripeValues}
      isLoading={isLoading}
      primaryButtonText="Pay & Enroll"
      validationSchema={AddPaymentMethodFormSchema}
      title="Billing Information"
    >
      {({ errors, handleBlur, handleChange, touched, values }) => (
        <React.Fragment>
          <TextInput
            id="stripe-checkout-name-input"
            feedback={errors.name}
            isInvalid={Boolean(touched.name && errors.name)}
            name="name"
            label="Name on Card"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
          />
          <BootstrapForm.Group className="stripe-input-group">
            <BootstrapForm.Label>Card Number</BootstrapForm.Label>
            <CardNumberElement options={STRIPE_ELEMENTS_OPTIONS} />
          </BootstrapForm.Group>
          <Row className="stripe-bottom-form-row">
            <BootstrapForm.Group
              as={Col}
              sm={7}
              lg={4}
              controlId="stripe-card-expiry"
              className="stripe-input-group"
            >
              <BootstrapForm.Label>Expiration Date</BootstrapForm.Label>
              <CardExpiryElement options={STRIPE_ELEMENTS_OPTIONS} />
            </BootstrapForm.Group>
            <BootstrapForm.Group
              as={Col}
              sm={5}
              lg={4}
              controlId="stripe-card-cvc"
              className="stripe-input-group"
            >
              <BootstrapForm.Label>CVC</BootstrapForm.Label>
              <CardCvcElement options={STRIPE_ELEMENTS_OPTIONS} />
            </BootstrapForm.Group>
            <TextInput
              id="stripe-checkout-zip-input"
              feedback={errors.zipCode}
              isInvalid={Boolean(touched.zipCode && errors.zipCode)}
              label="Zip Code"
              name="zipCode"
              cleaveOptions={{ blocks: [5], numericOnly: true }}
              onBlur={handleBlur}
              onChange={handleChange}
              lg={4}
              value={values.zipCode}
            />
            {hasPaymentMethods && (
              <PaymentMethodButton
                label="Use an Existing Credit Card"
                onClick={useExistingPaymentMethod}
              />
            )}
          </Row>
        </React.Fragment>
      )}
    </Form>
  );
}

export default AddPaymentMethodForm;
