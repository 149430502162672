import React, { ChangeEvent, HTMLAttributes } from 'react';
import Form from 'react-bootstrap/Form';
import './styles.scss';

export interface CheckInputProps extends HTMLAttributes<HTMLInputElement> {
  checked: boolean;
  disabled?: boolean;
  feedback?: string;
  id: string;
  inline?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  label?: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: 'checkbox' | 'radio';
}

function CheckInput(props: CheckInputProps): JSX.Element {
  const {
    checked,
    className,
    disabled,
    feedback,
    id,
    inline,
    isValid,
    isInvalid,
    name,
    onChange,
    label,
    type = 'checkbox',
  } = props;

  return (
    <Form.Check
      custom
      id={id}
      inline={inline}
      type={type}
      className={`check-input${className ? ` ${className}` : ''}`}
    >
      <Form.Check.Input
        checked={checked}
        disabled={disabled}
        isValid={isValid}
        isInvalid={isInvalid}
        name={name}
        onChange={onChange}
        type={type}
      />
      <Form.Check.Label>{label || <span className="sr-only" />}</Form.Check.Label>
      {feedback && (
        <Form.Control.Feedback type={isValid ? 'valid' : 'invalid'}>
          {feedback}
        </Form.Control.Feedback>
      )}
    </Form.Check>
  );
}

export default CheckInput;
