import React, { ReactElement } from 'react';
import { Formik, FormikConfig, FormikHelpers, FormikProps } from 'formik';
import BootstrapForm from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Typography from '../Typography';
import CanopyButton from '../Button';

export interface FormProps<T> {
  buttonDirection?: 'start' | 'center' | 'end';
  children: (props: FormikProps<T>) => ReactElement | ReactElement[];
  error?: string | string[];
  handlePrimaryButtonSubmit: (values: T, helpers: FormikHelpers<T>) => void;
  handleSecondaryButtonSubmit?: () => void;
  initialValues: T;
  isLoading?: boolean;
  isPrimaryButtonDisabled?: boolean;
  isSecondaryButtonDisabled?: boolean;
  primaryButtonText?: string;
  resettable?: boolean;
  secondaryButtonText?: string;
  title?: string;
  validationSchema?: FormikConfig<T>['validationSchema'];
}

const buttonDirectionClasses = {
  start: 'flex-start',
  center: 'flex-center',
  end: 'flex-end',
};

function Form<T>(props: FormProps<T>): JSX.Element {
  const {
    buttonDirection = 'start',
    children,
    error,
    handlePrimaryButtonSubmit,
    handleSecondaryButtonSubmit,
    initialValues,
    isLoading,
    isPrimaryButtonDisabled,
    isSecondaryButtonDisabled,
    primaryButtonText,
    resettable,
    secondaryButtonText = 'Cancel',
    title,
    validationSchema,
  } = props;

  const getFormErrors = (errors: string | string[]) => {
    if (typeof errors === 'string') {
      return errors;
    } else {
      return (
        <ul>
          {errors.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </ul>
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handlePrimaryButtonSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <BootstrapForm noValidate onSubmit={formikProps.handleSubmit}>
          {title && (
            <Typography
              className={`form-title ${error && 'form-title-with-error'}`}
              variant="h4"
            >
              {title}
            </Typography>
          )}
          {error && (
            <Alert variant="danger" className="form-level-error">
              <Alert.Heading>Uh Oh! Something's gone wrong!</Alert.Heading>
              {getFormErrors(error)}
            </Alert>
          )}
          {children(formikProps)}
          <div
            className={`canopy-form-submit-button-group ${buttonDirectionClasses[buttonDirection]}`}
          >
            <CanopyButton
              disabled={isPrimaryButtonDisabled}
              loading={isLoading}
              label={primaryButtonText}
              type="submit"
            />
            {(handleSecondaryButtonSubmit || resettable) && (
              <CanopyButton
                secondary
                disabled={isSecondaryButtonDisabled}
                onClick={resettable ? formikProps.resetForm : handleSecondaryButtonSubmit}
                label={resettable ? 'Reset' : secondaryButtonText}
              />
            )}
          </div>
        </BootstrapForm>
      )}
    </Formik>
  );
}

export default Form;
