import React, { HTMLAttributes, ReactElement } from 'react';
import './styles.scss';

export interface TypographyProps extends HTMLAttributes<HTMLFontElement> {
  children: string | ReactElement;
  color?: 'primary' | 'secondary' | 'tertiary' | 'gray' | 'white';
  spaced?: 1 | 2;
  uppercase?: boolean;
  variant?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'caption';
}

function Typography(props: TypographyProps) {
  const {
    children,
    className,
    color = 'secondary',
    spaced,
    uppercase,
    variant = 'p',
  } = props;

  const classes = `${color}${className ? ` ${className}` : ''}${
    spaced ? (spaced === 1 ? ' spaced-1' : ' spaced-2') : ''
  }${uppercase ? ' uppercase' : ''}`;

  const getVariant = () => {
    switch (variant) {
      case 'h1':
        return <h1 className={`canopy-h1 ${classes}`}>{children}</h1>;
      case 'h2':
        return <h2 className={classes}>{children}</h2>;
      case 'h3':
        return <h3 className={`canopy-h3 ${classes}`}>{children}</h3>;
      case 'h4':
        return <h4 className={`canopy-h4 ${classes}`}>{children}</h4>;
      case 'h5':
        return <h5 className={classes}>{children}</h5>;
      case 'h6':
        return <h6 className={`canopy-h6 ${classes}`}>{children}</h6>;
      case 'caption':
        return <p className={`canopy-caption ${classes}`}>{children}</p>;
      default:
        return <p className={classes}>{children}</p>;
    }
  };

  return <React.Fragment>{getVariant()}</React.Fragment>;
}

export default Typography;
