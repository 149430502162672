import React, { ReactElement } from 'react';
import Typography from '../Typography';

import './style.scss';

export interface FourOhFourProps {
  content?: ReactElement | ReactElement[];
}

function FourOhFour(props: FourOhFourProps): JSX.Element {
  const { content } = props;

  return (
    <div className="four-oh-four-container">
      <Typography className="status-code">404</Typography>
      <Typography variant="h1" uppercase spaced={2}>
        Page not found!
      </Typography>
      <Typography>
        The page you are looking for was moved, removed, renamed, or may never have
        existed.
      </Typography>
      {content && <div className="four-oh-four-content">{content}</div>}
    </div>
  );
}

export default FourOhFour;
