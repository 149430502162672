import React, { useEffect, useState, ReactElement } from 'react';
import {
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { Stripe, StripeError, PaymentIntent, PaymentMethod } from '@stripe/stripe-js';
import Typography from '../Typography';
import PaymentMethods from './PaymentMethods';
import AddPaymentMethodForm, { AddPaymentMethodFormValues } from './AddPaymentMethodForm';
import { useIsMounted } from '../../hooks';
import './styles.scss';

export type CreateNewPaymentIntentType = { inventoryId: number; promoCode?: string };
export type CreateExistingPaymentIntentType = {
  inventoryId: number;
  promoCode?: string;
  stripePaymentMethodId: string;
};

export type DeletePaymentMethodType = {
  stripePaymentMethodId: string;
};
interface StripeCheckoutFlowProps {
  createNewPaymentIntent: (
    newPaymentIntent: CreateNewPaymentIntentType,
  ) => Promise<{ secret: string }>;
  createExistingPaymentIntent?: (
    existingPaymentIntent: CreateExistingPaymentIntentType,
  ) => Promise<PaymentIntent>;
  deletePaymentMethod?: (
    paymentMethodId: DeletePaymentMethodType,
  ) => Promise<PaymentMethod>;
  disclaimer?: ReactElement | string;
  inventoryId: number;
  forceIsLoading?: boolean;
  onError?: (error: any) => void;
  onSuccess: () => void;
  paymentMethods?: PaymentMethod[];
}

function StripeCheckoutFlow(props: StripeCheckoutFlowProps) {
  const {
    createNewPaymentIntent,
    createExistingPaymentIntent,
    deletePaymentMethod,
    disclaimer,
    inventoryId,
    forceIsLoading,
    onError,
    onSuccess,
    paymentMethods,
  } = props;

  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState<boolean>(
    Boolean(
      !createExistingPaymentIntent || !paymentMethods || paymentMethods.length === 0,
    ),
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<StripeError | null>(null);

  const elements = useElements();
  const stripe = useStripe();
  const isMounted = useIsMounted();

  const handleStripeResult = (stripeResult: {
    paymentIntent?: PaymentIntent;
    error?: StripeError;
  }) => {
    /* istanbul ignore else */
    if (stripeResult.error) {
      setError(stripeResult.error);

      onError && onError(stripeResult);
    } else if (
      stripeResult.paymentIntent &&
      stripeResult.paymentIntent.status === 'succeeded'
    ) {
      onSuccess();
    }
  };

  const handleCreateNewPaymentMethod = async (values: AddPaymentMethodFormValues) => {
    setError(null);
    setIsLoading(true);

    try {
      const { name, zipCode } = values;
      const response = await createNewPaymentIntent({ inventoryId });
      const clientSecret = response.secret;

      /* istanbul ignore else */
      if (elements && stripe && clientSecret) {
        const cardNumberElement = elements.getElement(CardNumberElement);

        /* istanbul ignore else */
        if (cardNumberElement) {
          const stripeResult = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardNumberElement,
              billing_details: {
                name,
                address: {
                  postal_code: zipCode,
                },
              },
            },
            setup_future_usage: 'off_session',
          });

          handleStripeResult(stripeResult);
        }
      }
    } catch (e) {
      onError && onError(e);
    }

    setIsLoading(false);
  };

  const handleUseExistingPaymentMethod = async (paymentMethod: PaymentMethod) => {
    setError(null);
    setIsLoading(true);

    /* istanbul ignore else */
    if (createExistingPaymentIntent) {
      try {
        const paymentIntent = await createExistingPaymentIntent({
          inventoryId,
          // stripeCustomerId,
          stripePaymentMethodId: paymentMethod.id,
        });

        // When creating an existing paymentIntent, we automatically confirm the payment.
        if (paymentIntent.status === 'succeeded') {
          onSuccess();
        } else {
          /*
            If the payment failed due to an authentication_required decline code, use the declined PaymentIntent’s
            client secret and payment method with confirmCardPayment to allow the customer to authenticate the payment.
            
            See: https://stripe.com/docs/payments/save-during-payment
          */
          const clientSecret = paymentIntent.client_secret;
          const paymentMethodId = paymentIntent.last_payment_error?.payment_method?.id;

          /* istanbul ignore else */
          if (stripe && clientSecret && paymentMethodId) {
            const stripeResult = await stripe.confirmCardPayment(clientSecret, {
              payment_method: paymentMethodId,
            });

            handleStripeResult(stripeResult);
          }
        }
      } catch (e) {
        onError && onError(e);
      }
    }

    setIsLoading(false);
  };

  const handleDeletePaymentMethod = async (method: PaymentMethod) => {
    /* istanbul ignore else */
    if (paymentMethods && deletePaymentMethod) {
      try {
        await deletePaymentMethod({
          stripePaymentMethodId: method.id,
        });
      } catch (e) {
        onError && onError(e);
      }
    }
  };

  useEffect(() => {
    /*
      If we unmount the AddPaymentMethodForm, we need to unmount the Stripe elements as well.
    */
    if (isMounted.current && elements && !showAddPaymentMethod) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      /* istanbul ignore else */
      if (cardNumberElement) cardNumberElement.destroy();
      /* istanbul ignore else */
      if (cardExpiryElement) cardExpiryElement.destroy();
      /* istanbul ignore else */
      if (cardCvcElement) cardCvcElement.destroy();
    }

    // Reset error and paymentMethod when toggling between add/existing payments.
    setError(null);
  }, [elements, showAddPaymentMethod]);

  useEffect(() => {
    // If a user deletes their only payment method, let's show the add payment method form.
    if (paymentMethods && paymentMethods.length === 0) {
      setShowAddPaymentMethod(true);
    }
  }, [paymentMethods]);

  return (
    <div className="stripe-checkout">
      {paymentMethods && paymentMethods.length > 0 && !showAddPaymentMethod && (
        <PaymentMethods
          addNewPaymentMethod={() => setShowAddPaymentMethod(true)}
          isLoading={forceIsLoading || isLoading}
          onDelete={deletePaymentMethod ? handleDeletePaymentMethod : undefined}
          onSubmit={handleUseExistingPaymentMethod}
          paymentMethods={paymentMethods.filter((method) => method.card)}
        />
      )}
      {showAddPaymentMethod && (
        <AddPaymentMethodForm
          error={error}
          hasPaymentMethods={Boolean(paymentMethods && paymentMethods.length > 0)}
          isLoading={forceIsLoading || isLoading}
          onSubmit={handleCreateNewPaymentMethod}
          useExistingPaymentMethod={() => setShowAddPaymentMethod(false)}
        />
      )}
      {disclaimer && (
        <Typography className="disclaimer" variant="caption">
          {disclaimer}
        </Typography>
      )}
    </div>
  );
}

export interface StripeCheckoutProps extends StripeCheckoutFlowProps {
  config: Promise<Stripe | null>;
}

const STRIPE_ELEMENT_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Lato&display=swap',
    },
  ],
};

function StripeCheckout(props: StripeCheckoutProps): JSX.Element {
  const { config, ...rest } = props;

  return (
    <Elements stripe={config} options={STRIPE_ELEMENT_OPTIONS}>
      <StripeCheckoutFlow {...rest} />
    </Elements>
  );
}

export default StripeCheckout;
