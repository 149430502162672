import React, { useState, ChangeEvent } from 'react';
import { IoIosCloseCircle } from 'react-icons/io';
import Badge from 'react-bootstrap/Badge';
import Typography from '../Typography';
import { TextInput } from '../CanopyForm';
import Button from '../Button';
import { ProductType } from '../../types';

import './styles.scss';

export type PromoCodePayloadType = {
  inventoryId: number;
  promoCode: string;
};

export type PromoCodeResponseType = {
  price: number;
};

export type PromoCodeType = {
  info?: {
    code: string;
    price: number;
  };
  error?: Error;
};
export interface OrderSummaryProps {
  onSubmitPromoCode?: (promoCode: string) => void;
  product: ProductType;
  promoCode?: PromoCodeType | null;
  removePromoCode?: () => void;
  taxRate?: number;
}

function OrderSummary(props: OrderSummaryProps) {
  const { onSubmitPromoCode, product, promoCode, removePromoCode, taxRate } = props;
  const [promoCodeInput, setPromoCodeInput] = useState<string>('');

  const getPrice = () => {
    return product.price ? `$${(product.price / 100).toFixed(2)}` : 'FREE';
  };

  const getPromoDiscount = () => {
    return product.price && promoCode && promoCode.info
      ? `-$${((product.price - promoCode.info.price) / 100).toFixed(2)}`
      : '$0.00';
  };

  const getTaxAmount = () => {
    return taxRate && product.price
      ? ((promoCode && promoCode.info ? promoCode.info.price : product.price) / 100) *
          (taxRate / 100)
      : undefined;
  };

  const getTotal = () => {
    return product.price
      ? `$${(
          (promoCode && promoCode.info ? promoCode.info.price : product.price) / 100 +
          (getTaxAmount() || 0)
        ).toFixed(2)}`
      : 'FREE';
  };

  const handleChangePromoCode = (event: ChangeEvent<HTMLInputElement>) => {
    setPromoCodeInput(event.target.value);
  };

  const handleSubmitPromoCode = async () => {
    /* istanbul ignore else */
    if (onSubmitPromoCode && promoCodeInput) {
      onSubmitPromoCode(promoCodeInput);

      setPromoCodeInput('');
    }
  };

  return (
    <div className="order-summary-container">
      <div className="order-summary-header">
        <Typography uppercase>Order Summary</Typography>
      </div>
      <div className="order-summary-content">
        <div className="order-line-item">
          <div>
            <Typography>{product.name}</Typography>
            <Typography>{product.variant}</Typography>
          </div>
          <Typography>{getPrice()}</Typography>
        </div>
        {promoCode && promoCode.info ? (
          <div className="order-line-item order-promo-code">
            <Badge variant="secondary">
              {`Promo Code: ${promoCode.info.code}`}
              {removePromoCode && (
                <IoIosCloseCircle
                  onClick={removePromoCode}
                  data-testid="remove-promo-code"
                />
              )}
            </Badge>
            <Typography>{getPromoDiscount()}</Typography>
          </div>
        ) : null}
        {taxRate && product.price ? (
          <div className="order-line-item order-tax">
            <Typography>{`Sales Tax (${taxRate}%)`}</Typography>
            <Typography>{`$${getTaxAmount()?.toFixed(2)}`}</Typography>
          </div>
        ) : null}
        <div className="product-total-divider" />
        <div className="product-total">
          <Typography>Total</Typography>
          <Typography>{getTotal()}</Typography>
        </div>
        {onSubmitPromoCode && (
          <div className="promo-code">
            <TextInput
              id="stripe-checkout-promo-code"
              name="promoCode"
              placeholder="Promo Code"
              onChange={handleChangePromoCode}
              value={promoCodeInput}
              feedback={promoCode && promoCode.error ? promoCode.error.message : ''}
              isInvalid={Boolean(promoCode && promoCode.error)}
            />
            <Button
              label="Apply"
              small
              fullWidth
              outlined
              disabled={Boolean(!promoCodeInput)}
              onClick={handleSubmitPromoCode}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderSummary;
