import React, { ChangeEvent, FocusEvent } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Cleave from 'cleave.js';
import { CleaveOptions } from 'cleave.js/options';
import './styles.scss';

const getFormattedValue = (options: CleaveOptions, value?: string) => {
  const input = document.createElement('input');
  input.value = value || '';

  const cleave = new Cleave(input, options);
  const formattedValue = cleave.getFormattedValue();
  const lastChar = formattedValue[formattedValue.length - 1];

  return lastChar === options.delimiter || (options.delimiters || []).includes(lastChar)
    ? formattedValue.slice(0, -1)
    : formattedValue;
};

type ColWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface EnhancedControlProps {
  cleaveOptions?: CleaveOptions;
  disabled?: boolean;
  feedback?: string;
  isValid?: boolean;
  isInvalid?: boolean;
  name?: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  type?: string;
}

export interface TextInputProps extends EnhancedControlProps {
  horizontal?: boolean;
  id?: string;
  label?: string;
  large?: boolean;
  xs?: ColWidth;
  sm?: ColWidth;
  md?: ColWidth;
  lg?: ColWidth;
  xl?: ColWidth;
}

function EnhancedControl(props: EnhancedControlProps): JSX.Element {
  const {
    cleaveOptions,
    disabled,
    feedback,
    isValid,
    isInvalid,
    name,
    onChange,
    onBlur,
    placeholder,
    value,
    type,
  } = props;

  return (
    <React.Fragment>
      <Form.Control
        disabled={disabled}
        isValid={isValid}
        isInvalid={isInvalid}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        value={cleaveOptions ? getFormattedValue(cleaveOptions, value) : value}
        type={type}
      />
      {feedback && (
        <Form.Control.Feedback type={isValid ? 'valid' : 'invalid'}>
          {feedback}
        </Form.Control.Feedback>
      )}
    </React.Fragment>
  );
}

function TextInput(props: TextInputProps): JSX.Element {
  const {
    cleaveOptions,
    disabled,
    feedback,
    horizontal,
    id,
    isValid,
    isInvalid,
    label,
    large,
    name,
    onBlur,
    onChange,
    placeholder,
    value,
    type = 'text',
    xs,
    sm,
    md,
    lg,
    xl,
  } = props;

  const getGroupType = (): typeof Col | Row | undefined => {
    if (horizontal) {
      return Row;
    } else if (xs || sm || md || lg || xl) {
      return Col;
    } else {
      return undefined;
    }
  };

  const getHorizontalWidth = (
    breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
    width?: number,
  ): number | undefined => {
    if (width) {
      return width;
    } else if (breakpoint === 'sm') {
      return 9;
    } else {
      return undefined;
    }
  };

  return (
    <Form.Group
      as={getGroupType()}
      controlId={id}
      className={`text-input${large ? ' large-text-input' : ''}`}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
    >
      {label && (
        <Form.Label column={Boolean(horizontal)} sm={horizontal ? 3 : undefined}>
          {label}
        </Form.Label>
      )}
      {horizontal ? (
        <Col
          xs={getHorizontalWidth('xs', xs)}
          sm={getHorizontalWidth('sm', sm)}
          md={getHorizontalWidth('md', md)}
          lg={getHorizontalWidth('lg', lg)}
          xl={getHorizontalWidth('xl', xl)}
        >
          <EnhancedControl
            cleaveOptions={cleaveOptions}
            disabled={disabled}
            feedback={feedback}
            isValid={isValid}
            isInvalid={isInvalid}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            type={type}
          />
        </Col>
      ) : (
        <EnhancedControl
          cleaveOptions={cleaveOptions}
          disabled={disabled}
          feedback={feedback}
          isValid={isValid}
          isInvalid={isInvalid}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          type={type}
        />
      )}
    </Form.Group>
  );
}

export default TextInput;
