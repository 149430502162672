import React, { ReactElement } from 'react';
import { CheckInputProps } from './CheckInput';
import './styles.scss';

export interface CheckInputGroupProps {
  children: ReactElement<CheckInputProps>[];
  inline?: boolean;
  type: 'checkbox' | 'radio';
}

function CheckInputGroup(props: CheckInputGroupProps): JSX.Element {
  const { children, inline, type } = props;
  const elements = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      className: 'check-input-group-item',
      inline,
      type,
    });
  });

  return <React.Fragment>{elements}</React.Fragment>;
}

export default CheckInputGroup;
