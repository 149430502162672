import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';

export interface ButtonProps {
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
  outlined?: boolean;
  label?: string;
  loading?: boolean;
  secondary?: boolean;
  small?: boolean;
  type?: string;
}

function CanopyButton(props: ButtonProps): JSX.Element {
  const {
    disabled,
    fullWidth,
    onClick,
    outlined,
    label = 'Submit',
    loading,
    secondary,
    small,
    type,
  } = props;

  return (
    <Button
      block={Boolean(fullWidth)}
      className={`canopy-button${secondary ? ' secondary-button' : ''}${
        small ? ' small-button' : ''
      }`}
      disabled={Boolean(disabled) || Boolean(loading)}
      onClick={onClick}
      variant={outlined || secondary ? 'outline-secondary' : 'secondary'}
      type={type}
    >
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <React.Fragment>{label}</React.Fragment>
      )}
    </Button>
  );
}

export default CanopyButton;
